<script>
import { SuawMainContent } from "@/components";
import LogInForm from "@/features/users/auth/components/LogInForm.vue";

export default {
  name: "LogIn",
  components: { SuawMainContent, LogInForm }
};
</script>

<template>
  <SuawMainContent size="small">
    <LogInForm />
  </SuawMainContent>
</template>
